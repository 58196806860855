import { htmlToText } from "html-to-text";
import { useAtomValue } from "jotai";
import { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dateExtractor } from "../../../utils/dates";
import { adminContext } from "../../Context";
import { languageAtom } from "../../Layout/Header/state";
import AdminTools from "../../Reusable/AdminTools";
import DeleteModal from "../../Reusable/Card/Modals/DeleteModal";
import EditModal from "../../Reusable/Card/Modals/EditModal";
import { transformedImageUrl } from "../../../utils";

export default function ArticleCard({ post = {}, category = "" }) {
  const navigate = useNavigate();

  const { isAdmin } = useContext(adminContext);
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";
  const isArticle = category === "articles";

  //Global States
  const language = useAtomValue(languageAtom);

  // Local State
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const handleArticleChange = useCallback(
    () => {
      const currentCategory = isArticle ? "news" : category;

      if (!isHomePage) {
        navigate(`/${currentCategory}/${post._id}/`);
        window.scrollTo(0, 0);
      } else {
        navigate(`/${currentCategory}/${post._id}/`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, post]
  );

  const article = useMemo(() => {
    return {
      ...post,
      imageUrl: post?.pictures[0]?.url || post?.cover?.url,
      date: dateExtractor(post.date || post.createdAt, language),
      title: post[`title_${language}`],
      paragraph: htmlToText(post[`description_${language}`])
    };
  }, [post, language]);

  const cardImageUrl = transformedImageUrl({ url: article.imageUrl, ratio: "16:9" });

  return (
    <article className="flex flex-col bg-white relative shadow-sm p-2 items-start justify-start">
      {!isHomePage && (
        <AdminTools
          showAdminModal={(v) => setEditModalShow(v)}
          showDeleteModal={(v) => setDeleteModalShow(v)}
          isAdmin={isAdmin}
        />
      )}
      <img
        src={cardImageUrl}
        alt={`charity-armenia-${post._id}`}
        className="aspect-[5/3] w-full rounded-2 bg-gray-100 object-cover "
      />
      <div className="max-w-xl p-2 flex-col flex justify-between gap-2 md:gap-2">
        {isArticle && (
          <div className="flex items-center text-xs">
            <time className="text-gray-500 tracking-wider uppercase">{article.date}</time>
          </div>
        )}
        <h3
          className="font-normal mt-2 cursor-pointer hover:text-yellow-500 !leading-5 text-sm md:text-base lg:text-lg text-prmDark line-clamp-2 text-ellipsis overflow-hidden"
          onClick={handleArticleChange}>
          {article.title}
        </h3>
        <p className="text-[12px] md:text-sm line-clamp-2 font-light text-prmDark">
          {article.paragraph}
        </p>
      </div>
      <div>
        <DeleteModal
          data={article}
          type={category}
          id={article._id}
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
        />
        <EditModal
          data={article}
          type={category}
          id={article._id}
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
        />
      </div>
    </article>
  );
}
