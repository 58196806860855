import { useAtom, useSetAtom } from "jotai";
import { currentPageAtom, offsetAtom } from "./state";
import { classNames } from "../../../utils";

const PaginationItem = ({ page }) => {
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const setOffset = useSetAtom(offsetAtom);

  const getCurrentPage = (e) => {
    e.preventDefault();
    setCurrentPage(page);
    //offset has to be zero on first page
    setOffset((page - 1) * 6);
  };

  return currentPage !== page ? (
    <a
      href="#"
      onClick={(e) => getCurrentPage(e)}
      className={classNames(
        "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ",
        "hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      )}>
      {page}
    </a>
  ) : (
    <a
      href="#"
      onClick={(e) => getCurrentPage(e)}
      aria-current="page"
      className={classNames(
        "relative z-10 inline-flex items-center bg-yellow-400 px-4 py-2 text-sm font-semibold text-prmDark ",
        "focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-prmDark"
      )}>
      {page}
    </a>
  );
};

export default PaginationItem;
