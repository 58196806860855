import { useAtom, useSetAtom } from "jotai";
import { classNames, transformedImageUrl } from "../../../utils";
import { donateModalAtom, donorDetailsAtom } from "../../Donate/state";

function PrioCard({ donation }) {
  const { imageUrl, projectTitle, projectDescription, callToAction, donationText } = donation;

  // Global State
  const setIsModalOpen = useSetAtom(donateModalAtom);
  const [details, setCurrentCause] = useAtom(donorDetailsAtom);

  const onClickHandler = () => {
    setCurrentCause({ ...details, cause: projectTitle });
    setIsModalOpen(true);
  };

  return (
    <div
      className={classNames(
        "lg:max-w-xs max-w-[200px] md:max-w-[230px] w-full flex flex-col justify-start lg:justify-center  border-2 border-yellow-400 aspect-[3/4] md:aspect-square shadow-md relative",
        "before:bg-cyan-900 before:bg-opacity-50 before:backdrop-blur-[3px] before:z-10 hover:before:backdrop-blur-[2px] before:absolute before:inset-0",
        "group/item"
      )}>
      <img
        src={transformedImageUrl({ url: imageUrl })}
        alt={`cover-${projectTitle}`}
        className="object-cover w-full h-full grayscale group-hover/item:grayscale-[50%] absolute"
      />
      <div className="p-2 pt-3 text-white z-10 flex flex-col gap-2">
        <h1 className="text-[14px] lg:text-xl uppercase font-light">{projectTitle}</h1>
        <p className="text-[12px] lg:text-sm italic">{projectDescription}</p>
      </div>
      <div className="absolute overflow-hidden hidden lg:flex group-hover/item:p-3 font-medium flex-col gap-3 text-base z-30 w-full bg-white text-prmDark bottom-0 h-0 transition-all group-hover/item:h-32">
        <h4>{callToAction}</h4>
        <button onClick={onClickHandler} className="bg-yellow-400  py-1 cursor-pointer px-2">
          {donationText}
        </button>
      </div>
      <button
        onClick={onClickHandler}
        className="bg-yellow-400 text-[12px] bottom-0 absolute sm:text-lg py-1 z-10 text-prmDark cursor-pointer lg:hidden px-2  font-bold w-full">
        {donationText}
      </button>
    </div>
  );
}

export default PrioCard;
