import { XCircleIcon } from "@heroicons/react/24/solid";
import { useSetAtom } from "jotai";
import { RESET, useResetAtom } from "jotai/utils";
import { Modal } from "react-bootstrap";
import DonationForm from "./DonationForm";
import { defaultValuesAtom, donateModalAtom, donorDetailsAtom, errorAtom } from "./state";

function DonateModal(props) {
  const setIsModalOpen = useSetAtom(donateModalAtom);
  const setAmount = useSetAtom(defaultValuesAtom);
  const setErrorMessage = useSetAtom(errorAtom);
  const resetDetails = useResetAtom(donorDetailsAtom);

  function closeModal() {
    setIsModalOpen(false);
    setErrorMessage(false);
    resetDetails(RESET);
    setAmount(0);
  }

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="text-prmDark gap-3 flex flex-col lg:flex-row">
          <DonationForm />
        </div>
      </Modal.Body>
      <button
        onClick={closeModal}
        className="closeButton rounded-full font-bold top-8 right-8 lg:top-6 lg:right-6">
        <XCircleIcon className="h-6 w-6 text-gray-200 lg:text-gray-800 " />
      </button>
    </Modal>
  );
}

export default DonateModal;
