import { classNames } from "../../../utils";

const InfoGraphicItem = (props) => {
  const { title, number } = props;
  return (
    <div
      className={classNames(
        "md:shadow-sm p-2 px-4 py-3 flex flex-col md:w-36 lg:w-48 justify-center items-center rounded-none md:rounded-xl",
        "bg-gradient-to-t to-transparent from-white from-30%"
      )}>
      <div className="text-2xl lg:text-5xl font-light md:font-extralight text-yellow-500 font">
        {number}
      </div>
      <div className="uppercase font-extralight text-sm md:text-base tracking-wide text-prmDark">
        {title}
      </div>
    </div>
  );
};

export default InfoGraphicItem;
