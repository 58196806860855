import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { languageAtom } from "../../../Layout/Header/state";
import { containerTitleText } from "./content";
import { classNames } from "../../../../utils";

function ContainerTitle({ title, navTo }) {
  const navigate = useNavigate();
  const language = useAtomValue(languageAtom);
  const translation = containerTitleText(language);

  return (
    <div
      className={classNames(
        "text-prmDark p-2 pb-1 pt-3 mt-4 flex items-baseline border-b",
        !navTo ? "justify-start" : "justify-between"
      )}>
      <h3 className={"font-medium tracking-tighter text-2xl sm:text-3xl"}>{translation[title]}</h3>
      {navTo && (
        <span
          onClick={() => navigate(`${navTo}`)}
          className="uppercase tracking-widest text-xs sm:text-sm cursor-pointer">
          {translation.seeAll}
        </span>
      )}
    </div>
  );
}

export default ContainerTitle;
