export function headerNavText(language) {
  switch (language) {
    case "en":
      return {
        aboutUs: "About Us",
        patrons: "We are Grateful",
        news: "News",
        projects: "Our Projects",
        volunteers: "Our Volunteers",
        contactUs: "Contact Us"
      };
    case "de":
      return {
        aboutUs: "Über uns",
        patrons: "Wir sind dankbar",
        news: "Nachrichten",
        projects: "Unsere Projekte",
        volunteers: "Unsere Freiwilligen",
        contactUs: "Kontakt"
      };
    case "am":
      return {
        aboutUs: "Մեր Մասին",
        patrons: "Մեր Գործընկերները",
        news: "Նորություններ",
        projects: "Նախագծեր",
        volunteers: "Մեր Կամավորները",
        contactUs: "Կապ"
      };
    default:
  }
}
