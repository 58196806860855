import { useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";

function LazyLoadYouTube({ videoId }) {
  const [showVideo, setShowVideo] = useState(false);
  const thumbnailSrc = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

  if (showVideo) {
    return (
      <iframe
        className="aspect-video w-full flex-1"
        src={iframeSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen></iframe>
    );
  }

  return (
    <div className="relative" onClick={() => setShowVideo(true)}>
      <img src={thumbnailSrc} alt="Click to play video" />
      <div className="absolute z-10 w-full h-full flex justify-center items-center inset-0">
        <AiFillPlayCircle className="md:w-28 md:h-28 h-16 w-16 fill-white cursor-pointer hover:fill-gray-100" />
      </div>
    </div>
  );
}

export default LazyLoadYouTube;
