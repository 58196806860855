export const prioDonationText = {
  en: {
    artsakhFundraiser: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1698532115/HouseOfHope/statics/TatikPapik_vi1rwn.webp",
      projectTitle: "Fundraiser for the Forcibly Displaced Artsakh Armenians",
      projectDescription:
        "Armenian families from Artsakh are provided with hot meals, food, household goods, and clothing.",
      callToAction: "Donate to support displaces families from Artsakh",
      donationText: "Donate Now",
      badge: "Urgent Donation Call"
    },
    charityKitchen: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1648550506/HouseOfHope/vghgihiengvwvmwmi3yq.jpg",
      projectTitle: "Charity Kitchen",
      projectDescription:
        "Hot meal is provided to about 250 socially disadvantaged people living in Yerevan city, including Artsakh Armenian and Syrian Armenian refugees.",
      callToAction: "Donate to support for families in need",
      donationText: "Donate Now"
    },
    homeCare: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697694946/HouseOfHope/file_wueyjh.jpg",
      projectTitle: "Home Care",
      projectDescription:
        "In Yerevan, 25 solitary elderly with motion issues are given social-psychological support.",
      callToAction: "Donate to aid the elderly who reside alone.",
      donationText: "Donate Now"
    },
    childrenFood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695701/HouseOfHope/file_brttc8.jpg",
      projectTitle: "Children food",
      projectDescription:
        "Over 100 children attending kindergartens in border-near villages of Tavush region receive daily meals.",
      callToAction: "Donate to help children in border regions.",
      donationText: "Donate Now"
    },
    healthyChildhood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695232/HouseOfHope/file_e6ohx0.jpg",
      projectTitle: "Hopeful and Healthy Childhood",
      projectDescription:
        "Social-psychological care is provided to over 70 children of Artsakh and Syrian-Armenian refugee families in Yerevan.",
      callToAction: "Support displaced children from Artsakh and Syria with your donation.",
      donationText: "Donate Now"
    },
    socialAid: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695570/HouseOfHope/file_tozxxw.jpg",
      projectTitle: "Social immediate aid",
      projectDescription:
        "Essential food and non-food aid is being given to over 600 extremely needy families in RA regions and Yerevan city.",
      callToAction: "Donate to aid families in extreme need.",
      donationText: "Donate Now"
    }
  },
  de: {
    artsakhFundraiser: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1698532115/HouseOfHope/statics/TatikPapik_vi1rwn.webp",
      projectTitle: "Spendenaktion für die zwangsvertriebenen Armenier aus Arzach",
      projectDescription:
        "Armenische Familien aus Artsakh werden mit warmer Mahlzeit und Lebensmitteln sowie Haushaltswaren und Kleidung versorgt",
      callToAction: "Unterstützung an die gewaltsam vertriebenen armenischen Familien aus Artsakh",
      donationText: "Jetzt Spenden",
      badge: "Dringender Spendenaufruf"
    },
    charityKitchen: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1648550506/HouseOfHope/vghgihiengvwvmwmi3yq.jpg",
      projectTitle: "Küche der Barmherzigkeit",
      projectDescription:
        "Etwa 250 sozial benachteiligten Menschen in Jerewan, darunter armenische Flüchtlingen aus Artsakh und Syrien, werden mit einer warmen Mahlzeit versorgt.",
      callToAction: "Unterstützung an die Familien in Not",
      donationText: "Jetzt Spenden"
    },
    homeCare: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697694946/HouseOfHope/file_wueyjh.jpg",
      projectTitle: "Hauspflege",
      projectDescription:
        "25 älteren Menschen in Eriwan, die Bewegungsprobleme haben und allein sind, wird sozialpsychologische Hilfe geleistet",
      callToAction: "Unterstützung an die alleinstehenden alten Leuten",
      donationText: "Jetzt Spenden"
    },
    childrenFood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695701/HouseOfHope/file_brttc8.jpg",
      projectTitle: "Kinderspeisung",
      projectDescription:
        "Es wird die tägliche Mahlzeit für insgesamt 106 Kinder bereitgestellt, die Kindergärten in den grenznahen Ortschaften der Region Tavush besuchen",
      callToAction: "Unterstützung an die Kinder in Grenzgebieten",
      donationText: "Jetzt Spenden"
    },
    healthyChildhood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695232/HouseOfHope/file_e6ohx0.jpg",
      projectTitle: "Hoffnungsvolle und gesunde Kindheit",
      projectDescription:
        "Insgesamt 70 Kindern aus artsakh-armenischen und syrisch-armenischen Flüchtlingsfamilien in Jerewan werden sozialpsychologische Unterstützung geleistet",
      callToAction: "Unterstützung an die vertriebenen Kinder aus Artsakh und Syrien",
      donationText: "Jetzt Spenden"
    },
    socialAid: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695570/HouseOfHope/file_tozxxw.jpg",
      projectTitle: "Soziale Nothilfe",
      projectDescription:
        "Insgesamt 600 extrem bedürftigen Familien in den Regionen der RA und in der Stadt Eriwan wird Lebensmittel- und Non-Food-Hilfe geleistet.",
      callToAction: "Unterstützung an die Familien in extremer Not",
      donationText: "Jetzt Spenden"
    }
  },
  am: {
    artsakhFundraiser: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1698532115/HouseOfHope/statics/TatikPapik_vi1rwn.webp",
      projectTitle: "Օգնություն Արցախից բռնի տեղահանված արցախահայերին",
      projectDescription:
        "Արցախահայ ընտանիքներին տրամադրվում է տաք և չոր սնունդ, ինչպես նաև կենցաղային պարագաներ և հագուստ",
      callToAction: "Աջակցել բռնի տեղահանված արցախահայ ընտանիքներին",
      donationText: "Նվիրաբերել",
      badge: "Շտապ հավաքագրում"
    },
    charityKitchen: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1648550506/HouseOfHope/vghgihiengvwvmwmi3yq.jpg",
      projectTitle: "Գթության խոհանոց",
      projectDescription:
        "Տաք սնունդ է տրամադրվում Երևան քաղաքի շուրջ 250 սոցիալապես խիստ անապահով անձանց՝ ներառյալ արցախահայ և սիրիահայ փախստականներ։   ",
      callToAction: "Աջակցել կարիքի մեջ գտնվող ընտանքիներին ",
      donationText: "Նվիրաբերել"
    },
    homeCare: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697694946/HouseOfHope/file_wueyjh.jpg",
      projectTitle: "Տնային խնամք",
      projectDescription:
        "Սոցիալ-հոգեբանական օգնություն է տրամադրվում Երևան քաղաքում բնակվող 25 հենաշարժողական խնդիրների ունեցող, միայնակ տարեցներին:",
      callToAction: "Աջակցել միայնակ տարեցներին",
      donationText: "Նվիրաբերել"
    },
    childrenFood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695701/HouseOfHope/file_brttc8.jpg",
      projectTitle: "Մանկական սնունդ",
      projectDescription:
        "Ապահովվում է Տավուշի մարզի սահմանամերձ բնակավայրերի մանկապարտեզներ հաճախող ընդհանուր առմամբ 106 երեխաների ամենօրյա սնունդը։",
      callToAction: "Աջակցել սահմանամերձ բնակավայրերի երեխաներին",
      donationText: "Նվիրաբերել"
    },
    healthyChildhood: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695232/HouseOfHope/file_e6ohx0.jpg",
      projectTitle: "Հույսով լի և առողջ մանկություն",
      projectDescription:
        "Սոցիալ-հոգեբանական օգնություն է տրամադրվում Երևան քաղաքում բնակվող արցախայ և սիրիահայ փախստական ընտանիքների ընդհանուր առմամբ 70 երեխաներին։",
      callToAction: "Աջակցել արցախահայ և սիրիահայ փախստական երեխաներին",
      donationText: "Նվիրաբերել"
    },
    socialAid: {
      imageUrl:
        "https://res.cloudinary.com/di8iypeft/image/upload/v1697695570/HouseOfHope/file_tozxxw.jpg",
      projectTitle: "Սոցիալական շտապօգնություն",
      projectDescription:
        "Պարենային և ոչ պարենային օգնություն է տրամադրվում ՀՀ մարզերում և Երևան  քաղաքում բնակվող ընդհանուր առմամբ 600 ծայրահեղ կարիքավոր ընտանիքներին",
      callToAction: "Աջակցել ծայրահեղ կարիքավոր ընտանքիներին",
      donationText: "Նվիրաբերել"
    }
  }
};
