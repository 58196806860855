import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { HiCheckCircle, HiMinusCircle } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { classNames } from "../../utils";
import { languageAtom } from "../Layout/Header/state";
import { paymentStatusPageText } from "./content";
import useApi from "../../axios/useAxios";
import FluidContainer from "../UI/Layout/FluidContainer";

const StatusPage = () => {
  const { axiosFetch } = useApi();
  const [status, setStatus] = useState(null);
  const [queryParameters] = useSearchParams();
  const orderId = queryParameters.get("orderId");
  const navigate = useNavigate();

  const language = useAtomValue(languageAtom);
  const { greetingMessage, statusMessage, homeButtonText } = paymentStatusPageText[language];

  useEffect(
    () => {
      const paymentStatus = async () => {
        if (!orderId) {
          navigate("/");
        }

        const { data } = await axiosFetch({
          method: "post",
          url: "/payment/status",
          requestConfig: {
            data: { orderId }
          }
        });

        if (data) {
          setStatus(data);
        }

        return data;
      };
      paymentStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!status)
    return (
      <div className="h-screen w-full flex gap-3 flex-column justify-center items-center">
        <div className="text-prmDark">...loading</div>
      </div>
    );

  const paymentStateMessage = status?.paymentAmountInfo?.paymentState;
  const isSuccessful = status?.orderStatus === 2;
  const donor = status?.cardAuthInfo?.cardholderName;

  return (
    <FluidContainer>
      <div className="h-screen w-full flex gap-3 flex-column justify-center items-center text-prmDark">
        <div className="flex flex-column gap-2 items-center justify-center">
          {isSuccessful ? (
            <HiCheckCircle className="text-darkGreen w-10 h-10 " />
          ) : (
            <HiMinusCircle className=" text-red-800 w-10 h-10 " />
          )}
          <h1
            className={classNames(
              isSuccessful ? "text-darkGreen" : "text-red-800",
              "font-semibold"
            )}>
            PAYMENT {paymentStateMessage}!
          </h1>
          {isSuccessful && (
            <p className="text-sm text-center">{`${greetingMessage} ${donor} ${statusMessage}`}</p>
          )}
        </div>
        <a
          href="/"
          className="text-prmDark font-medium text-base shadow-sm py-2 px-4 rounded-md bg-lightGreen hover:bg-hoverGreen hover:text-prm">
          {homeButtonText}
        </a>
      </div>
    </FluidContainer>
  );
};

export default StatusPage;
