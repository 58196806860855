import "swiper/css";
import "swiper/css/effect-cards";

import { useAtomValue } from "jotai";
import { EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { languageAtom } from "../../Layout/Header/state";
import { prioDonationText } from "./content";
import PrioSwiperCard from "./PrioSwiperCard";

export default function SwiperCards() {
  const language = useAtomValue(languageAtom);

  return (
    <div className="sm:hidden overflow-hidden">
      <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper">
        {Object.keys(prioDonationText.en).map((d) => {
          const content = prioDonationText[language][d];
          return content ? (
            <SwiperSlide key={content.projectTitle}>
              <PrioSwiperCard donation={content} />
            </SwiperSlide>
          ) : null;
        })}
      </Swiper>
    </div>
  );
}
