import { RiDeleteBin6Fill, RiEdit2Fill } from "react-icons/ri";
import { Fragment } from "react";
import "./style.css";

const AdminTools = ({ showAdminModal, showDeleteModal, isAdmin }) => {
  return (
    <Fragment>
      {isAdmin && (
        <div className="card-admin-tools">
          <RiEdit2Fill onClick={() => showAdminModal(true)} />
          <RiDeleteBin6Fill onClick={() => showDeleteModal(true)} />
        </div>
      )}
    </Fragment>
  );
};

export default AdminTools;
