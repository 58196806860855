import { classNames } from "../../../utils";

export default function CardContainer({ children }) {
  return (
    <div
      className={classNames(
        "mx-auto my-4 grid gap-y-10 gap-x-4 lg:mx-0 w-full",
        `grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4`
      )}>
      {children}
    </div>
  );
}
