import { currencyAtom, defaultValuesAtom, donorDetailsAtom, errorAtom } from "../state";
import { HeartIcon } from "@heroicons/react/24/solid";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";

import { languageAtom } from "../../Layout/Header/state";
import { donationTranslatedText } from "../content";
import useApi from "../../../axios/useAxios";
import CustomValues from "./CustomValues";
import DefaultValues from "./DefaultValues";
import DonorDetails from "./DonorDetails";

function DonationForm() {
  const { axiosFetch } = useApi();

  const language = useAtomValue(languageAtom);
  const { form: { donateButton, title } = {} } = donationTranslatedText[language];

  //Local State
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  //Global State
  const amount = useAtomValue(defaultValuesAtom);
  const setErrorMessage = useSetAtom(errorAtom);
  const { icon, code } = useAtomValue(currencyAtom);
  const { name: fullName, email, isAnonymous, cause } = useAtomValue(donorDetailsAtom);

  const order = {
    fullName,
    email,
    amount,
    anonymous: isAnonymous,
    currency: code,
    cause
  };

  const createOrder = () => {
    return axiosFetch({
      method: "post",
      url: "/orders",
      requestConfig: {
        data: order
      }
    });
  };

  const processPayment = (order) => {
    const { amount, orderNumber, currency } = order;

    return axiosFetch({
      method: "post",
      url: "/payment",
      requestConfig: {
        data: { amount, orderNumber, currency }
      }
    });
  };

  async function handlePayment() {
    if (!amount || !fullName) {
      setErrorMessage("No Value Inserted");
      return undefined;
    }

    // handles heart animation
    setIsButtonClicked(true);

    try {
      const { data } = await createOrder();
      if (data.orderNumber) {
        const {
          data: { formUrl }
        } = await processPayment(data);
        if (formUrl && window !== "undefined") {
          window.location.replace(formUrl);

          // handles heart animation
          await new Promise(() => setTimeout(() => setIsButtonClicked(false), 1000));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="bg-prm rounded-md  p-3 just-between flex flex-col">
      <div className="h-full">
        <div className="flex p-2 justify-center gap-2">
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
        <form action="get" className="flex w-full items-center flex-col gap-1 mt-3">
          <DefaultValues />
          <CustomValues />
          <DonorDetails />
        </form>
      </div>
      <button
        className={`text-white w-full text-sm flex justify-center gap-2 bg-prmBlue hover:bg-hoverBlue p-2 rounded-sm`}
        onClick={handlePayment}>
        <HeartIcon className={`h-5 w-5 text-red-500 ${isButtonClicked ? "clicked" : ""}`} />
        {donateButton} {!!amount && icon + amount.toLocaleString()}
      </button>
    </div>
  );
}

export default DonationForm;
