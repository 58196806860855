export const shortParagraphText = {
  en: {
    readMoreButtonText: "read more",
    readLessButtonText: "read less"
  },
  de: {
    readMoreButtonText: "mehr lesen",
    readLessButtonText: "weniger lesen"
  },
  am: {
    readMoreButtonText: "կարդալ ավելին",
    readLessButtonText: "փակել"
  }
};
