import { useAtomValue } from "jotai";
import { classNames, transformedImageUrl } from "../../../utils";
import { languageAtom } from "../../Layout/Header/state";
import { homeContentText } from "../content";
import AnniversaryBadge from "./AnniversaryBadge";

const HeroElement = () => {
  const language = useAtomValue(languageAtom);
  const isArm = language === "am";

  const {
    jumbotron: { mainTitle },
    greetings: { header } = {}
  } = homeContentText(language);

  return (
    <div
      className={classNames(
        "before:from-white before:bg-[#569b6c82] before:bg-gradient-to-t before:to-40% before:absolute before:inset-0 before:z-10",
        "relative aspect-[3/2] sm:aspect-[5/2] flex w-full flex-col justify-center items-center",
        isArm ? "font-art" : "font-num"
      )}>
      <img
        src={transformedImageUrl({
          url: "https://res.cloudinary.com/di8iypeft/image/upload/v1698090133/HouseOfHope/statics/our-story_wfcp7e.jpg",
          width: 1000
        })}
        alt="hero-image"
        className="absolute inset-0 object-cover w-full h-full"
      />

      <div className="w-full max-w-screen-xl gap-1 lg:gap-2 relative z-10 flex justify-center items-start flex-col p-4">
        <p className="lg:text-3xl tracking-wide text-xl  sm:text-lg text-white w-fit">
          {mainTitle}
        </p>
        <h1 className="text-4xl lg:text-6xl font-bold">{header}</h1>
        <AnniversaryBadge />
      </div>
    </div>
  );
};

export default HeroElement;
