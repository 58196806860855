export const partnerContent = {
  am: {
    navHeaderText: "Մեր Գործընկերները",
    mainTitleText: "Մենք Երախտապարտ ենք",
    mainParagraphText:
      "«Հուսո տուն բարեգործական կենտրոն հիմնադրամը» իր երախտագիտությունն է արտահայտում ինչպես այսօրվանից մեր կողքին կանգնող, այնպես էլ տասնամյակներ շարունակ մեր հայրենիքից և աշխարհի բոլոր ծայրերից մեզ աջակցության ձեռք մեկնած կառույցներին և  անհատներին։ Ձեր անգնահատելի աջակցության շնորհիվ հնարավոր են դառնում իրականացնել ծրագրեր, նախաձեռնություններ, միջոցառումներ, որոնք օգնում են կարիքի մեջ հայտնված մարդկանց թոթափելու սոցիալական ծանր բեռը, ապրելու անվտանգ ու արժանավայել։ Մենք բոլորս միասին փորձում ենք վաղվա լավ օրվա հույս պարգևել ինչպես կյանքի մայրամուտի ճամփան բռնած մեր հայրենակիցներին, այնպես էլ կյանքի արշալույսին իրենց առաջին քայլերն անող աճող սերնդին։",
    mainParagraphFooterText: "Հույսով գործը դարձնենք իրողություն։",
    deleteModalText: {
      toDeleteText: "Այո",
      notToDeleteText: "Ոչ",
      askToDeleteText: "Ցանկանում եք հեռացնե՞լ"
    }
  },
  en: {
    navHeaderText: "Our Partners",
    mainTitleText: "We are grateful",
    mainParagraphText:
      'The "House of Hope Charity Center Foundation" expresses its gratitude, both to the individuals standing by us from today, as well as to the institutions and individuals who have supported us for decades from our homeland and from all over the world. Due to your invaluable support, it becomes possible to implement projects, initiatives, events that help people in need to relieve the heavy social burden, to live safely and with dignity. Together we try to give hope for a good future, both to our compatriots who are on the way to the sunset of life and to the growing generation who are taking their first steps at the dawn of life.',
    mainParagraphFooterText: "We are grateful for your continues support",
    deleteModalText: {
      toDeleteText: "Yes",
      notToDeleteText: "No",
      askToDeleteText: "Are you sure you want to remove this?"
    }
  },
  de: {
    navHeaderText: "Unsere Partner",
    mainTitleText: "Wir sind dankbar",
    mainParagraphText:
      'Die Stiftung "Wohltätigkeitszentrum Haus der Hoffnung" bedankt sich bei den Menschen, sowie bei den Einrichtungen und Einzelpersonen aus unserer Heimat und aus aller Welt, die uns heute zur Seite stehen und seit Jahrzehnten unterstützen. Dank Ihrer unschätzbaren Unterstützung wird es möglich, Projekte, Initiativen und Veranstaltungen umzusetzen, die Menschen in Not helfen, die schwere soziale Last zu vermindern, sicher und in Würde zu leben. Gemeinsam versuchen wir, Hoffnung auf ein gutes Morgen zu geben, sowohl unseren Landsleuten, die auf dem Weg zum Lebensabend sind, als auch der heranwachsenden Generation, die ihre ersten Schritte des Lebens macht.',
    mainParagraphFooterText: "Wir sind dankbar für Ihre Unterstützung.",
    deleteModalText: {
      toDeleteText: "Ja",
      notToDeleteText: "Nein",
      askToDeleteText: "Sind Sie sich sicher, dass Sie das entfernen wollen?"
    }
  }
};
