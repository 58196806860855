import { Helmet } from "react-helmet";

const DynamicHelmet = ({ title }) => {
  const imageUrl =
    "https://res.cloudinary.com/di8iypeft/image/upload/v1698597630/HouseOfHope/statics/Asset_6_fxwwug.svg";
  const description =
    "Support initiatives aiding displaced families, the socially disadvantaged, isolated elderly with motion issues, children in border areas, displaced children, and providing urgent assistance to extremely needy families.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* If you need to set open graph tags for social sharing */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {/* If you need to set Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  );
};

export default DynamicHelmet;
