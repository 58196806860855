export function containerTitleText(language) {
  switch (language) {
    case "en":
      return {
        news: "News",
        seeAll: "See All",
        projects: "Projects",
        contactUs: "Contact Us",
        volutneers: "Volunteers",
        aboutUs: "About Us"
      };
    case "de":
      return {
        news: "Aktuelles",
        seeAll: "Alle Anzeigen",
        projects: "Projecte",
        contactUs: "Kontakt",
        volutneers: "Freiwillige",
        aboutUs: "Über uns"
      };
    case "am":
      return {
        news: "Նորություններ",
        seeAll: "Տեսնել Բոլորը",
        projects: "Նախագծերը",
        contactUs: "Կապվել Մեզ Հետ",
        volunteers: "Կամավորները",
        aboutUs: "Մեր Մասին"
      };
    default:
  }
}
