import { lightBoxImgUrlAtom, lightBoxModalAtom } from "../../LightBoxModal/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { transformedImageUrl } from "../../../utils";
import { Carousel, Container } from "react-bootstrap";
import { dateExtractor } from "../../../utils/dates";
import { languageAtom } from "../../Layout/Header/state";
import LightBoxModal from "../../LightBoxModal";
import ShortParagraph from "../ShortParagraph";
import SpinnerLoader from "../Spinner";

function SinglePostWrapper({ data, isLoading, category }) {
  const language = useAtomValue(languageAtom);
  const [isLightBoxOpen, setIsLightBoxOpen] = useAtom(lightBoxModalAtom);
  const setImageUrl = useSetAtom(lightBoxImgUrlAtom);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  const lightBoxToOpen = (url) => {
    if (isLightBoxOpen) return;

    setImageUrl(url);
    setIsLightBoxOpen(true);
  };

  const articleDate = (data && data?.date) || data?.createdAt;
  const isGallery = data?.pictures?.length > 1;
  const isArticle = category === "articles";
  return (
    <Container className="flex md:p-4 flex-col">
      <div className="aspect-square outline shadow-md p-2 md:p-4 w-full overflow-hidden md:aspect-[11/4]">
        <Carousel
          interval={null}
          variant="dark"
          indicators={isGallery}
          controls={false}
          className="h-full flex justify-center items-center">
          {data?.pictures?.length > 0 ? (
            data?.pictures?.map((picture, index) => (
              <Carousel.Item key={index}>
                <div className="w-full h-full md:aspect-[11/4]">
                  <img
                    onClick={() => lightBoxToOpen(picture?.url)}
                    className="h-full w-full object-contain aspect-square md:aspect-video"
                    src={transformedImageUrl({ url: picture?.url, width: 1000, ratio: "16:9" })}
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              </Carousel.Item>
            ))
          ) : data?.cover?.url ? (
            <div className="w-full h-full mx-auto border">
              <img
                className="h-full w-full object-contain aspect-square md:aspect-video p-2"
                src={transformedImageUrl({ url: data?.cover?.url, isLarge: true, ratio: "16:9" })}
                onClick={() => lightBoxToOpen(data?.cover?.url)}
                alt="slide cover"
              />
            </div>
          ) : (
            <div />
          )}
        </Carousel>
        <LightBoxModal />
      </div>
      <div className="flex flex-col items-start justify-center gap-2 mt-4 text-prmDark">
        {isArticle && (
          <span className="text-[12px] sm:text-sm tracking-wider uppercase font-light rounded-sm">
            {dateExtractor(articleDate, language)}
          </span>
        )}
        <h1 className="sm:text-2xl font-light">{data && data[`title_${language}`]}</h1>
      </div>
      <ShortParagraph data={data && data[`description_${language}`]} language={language} />
    </Container>
  );
}

export default SinglePostWrapper;
