export const footerTranslationText = {
  en: {
    navigation: {
      gratefulText: { name: "We are grateful", href: "/partners" },
      ourProjectsText: { name: "Our Projects", href: "/projects" },
      ourNewsText: { name: "News", href: "/news" },
      aboutUsText: { name: "About Us", href: "/about-us" },
      privacyText: { name: "Privacy Policy", href: "/privacy" }
    },
    copyrightText: `© ${new Date().getFullYear()} House of Hope. All rights reserved`
  },
  de: {
    navigation: {
      gratefulText: { name: "Wir sind dankbar", href: "/partners" },
      ourProjectsText: { name: "Unsere Projekte", href: "/projects" },
      ourNewsText: { name: "Nachrichten", href: "/news" },
      aboutUsText: { name: "Über uns", href: "/about-us" },
      privacyText: { name: "Datenschutz-Bestimmungen", href: "/privacy" }
    },
    copyrightText: `© ${new Date().getFullYear()} Haus der Hoffnung. Alle Rechte vorbehalten.`
  },
  am: {
    navigation: {
      gratefulText: { name: "Շնորհակալ ենք", href: "/partners" },
      ourProjectsText: { name: "Մեր Նախագծերը", href: "/projects" },
      ourNewsText: { name: "Նորություններ", href: "/news" },
      aboutUsText: { name: "Մեր Մասին", href: "/about-us" },
      privacyText: { name: "Գաղտնիության քաղաքականություն", href: "/privacy" }
    },
    copyrightText: `© ${new Date().getFullYear()} հուսո Տուն. Բոլոր իրավունքները պաշտպանված են։`
  }
};
