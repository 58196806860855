import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./style.css";

import { adminContext, ProjectContext } from "../Context";
import EditModal from "../Reusable/Card/Modals/EditModal";
import SinglePostWrapper from "../Reusable/SinglePostWrapper";
import SpinnerLoader from "../Reusable/Spinner";

import { useAtomValue } from "jotai";
import useAxios from "../../axios/useAxios";
import { languageAtom } from "../Layout/Header/state";
import Error from "../Reusable/Error";
import ArticleCard from "../UI/Cards/ArticleCard";
import CardContainer from "../UI/Layout/CardContainer";
import { projectContentText } from "./content";
import DynamicHelmet from "../Helmet/DynamicHelmet";

function Projects() {
  const { isAdmin } = useContext(adminContext);
  const language = useAtomValue(languageAtom);

  const [modalShow, setModalShow] = useState(false);
  const { archivedText } = projectContentText[language];

  const { id } = useParams();

  const {
    response: { projects, archived, category, currentProject } = {},
    loading,
    error,
    axiosFetch
  } = useAxios();

  const params = {
    currentId: id,
    limit: 8
  };

  async function fetchProjects() {
    await axiosFetch({
      url: `/projects`,
      params
    });
  }

  useEffect(
    () => {
      fetchProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <ProjectContext.Provider value={fetchProjects}>
      <DynamicHelmet title={"HOH | Projects"} />
      {isAdmin && (
        <div className="admin-tools absolute top-16 sm:top-20 z-10">
          <div
            className="bg-darkGreen text-sm px-2 py-1 cursor-pointer"
            onClick={() => setModalShow(true)}>
            Create
          </div>
          <EditModal type="projects" show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
      <SinglePostWrapper data={currentProject} isLoading={loading} category={category} />
      {error && <Error />}
      <Container>
        <CardContainer>
          {projects?.map((post) => (
            <ArticleCard post={post} key={post._id} category={category} />
          ))}
        </CardContainer>
        {/* Create a container for archived cards */}
        {archived?.length && (
          <div className="bg-yellow-400 rounded-sm p-2">
            <div className="text-prmDark uppercase font-light tracking-wider">{archivedText}</div>
            <CardContainer>
              {archived?.map((post) => (
                <ArticleCard post={post} key={post._id} category={category} />
              ))}
            </CardContainer>
          </div>
        )}
      </Container>
    </ProjectContext.Provider>
  );
}

export default Projects;
