import { classNames } from "../../../utils";

export const InputWrapper = ({ children }) => {
  return (
    <div
      className={classNames(
        "w-full m-2 flex px-2 border rounded-md justify-between shadow-sm custom-values"
      )}>
      {children}
    </div>
  );
};

export const FormLabel = ({ isError, label, forInput, children }) => {
  return (
    <label
      htmlFor={forInput}
      className={classNames(
        "text-[0.5rem] p-1 tracking-widest w-full outline-none",
        isError ? "text-red-600" : ""
      )}>
      {label?.toUpperCase()}
      {children}
    </label>
  );
};

export const FormInput = ({ name, type, id, callback, value, required = false }) => {
  return (
    <input
      id={id}
      name={name}
      className="text-lg w-full text-[#4079e7] ml-1 p-1 no-spinner outline-none"
      type={type}
      onChange={callback}
      value={value}
      required={required}
    />
  );
};

export const FormCheckBox = ({ name, type, id, callback, value, required = false }) => {
  return (
    <input
      id={id}
      name={name}
      className="h-6 w-6 cursor-pointer border-2 relative z-20 ml-1 p-1 no-spinner outline-none"
      type={type}
      onChange={callback}
      checked={value}
      required={required}
    />
  );
};

export const FormTextArea = ({ name, rows = 5, id, callback, value, required = false }) => {
  return (
    <textarea
      id={id}
      className="text-lg w-full text-prmDark ml-1 p-1 no-spinner outline-none"
      rows={rows}
      name={name}
      onChange={callback}
      value={value}
      required={required}
    />
  );
};

export const FormSelect = ({ selections, callback, currentCause }) => {
  if (!selections?.length) return null;

  return (
    <select
      onChange={callback}
      className="w-full max-w-7 appearance-none bg-white overflow-hidden h-8 text-sm font-light outline-none cursor-pointer"
      name="cause"
      id="cause">
      <option value={currentCause}>{currentCause}</option>
      {selections.map((item, i) => (
        <option key={`${item}-${i}`} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};
