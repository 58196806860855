import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

export const donateModalAtom = atom(false);

export const defaultValuesAtom = atom(0);

export const donorDetailsAtom = atomWithReset({
  name: "",
  email: "name@email.com",
  isAnonymous: false,
  cause: ""
});

export const errorAtom = atom(false);

const currencyStore = {
  text: "amd",
  icon: "֏",
  code: "051"
};

export const currencyAtom = atom(currencyStore);
