import ls from "localstorage-slim";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import AboutUs from "pages/about-us";
import Admin from "./components/Admin/Admin";
import { adminContext } from "./components/Context";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import Home from "./components/Home";
import News from "./components/News";
import Partners from "./components/Partners";
import PrivacyPolicy from "./components/Privacy";
import Projects from "./components/Projects/index.jsx";
import StatusPage from "./components/StatusPage";
import DonateModal from "./components/Donate/DonateModal";
import { useAtomValue } from "jotai";
import { donateModalAtom } from "./components/Donate/state";

function App() {
  const admin = ls.get("house_admin");
  const { pathname } = useLocation();

  const [isAdmin, setIsAdmin] = useState(admin ?? false);
  const isModalOpen = useAtomValue(donateModalAtom);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <adminContext.Provider value={{ isAdmin, admin, setIsAdmin }}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/projects/:id" element={<Projects />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/paymentStatus" element={<StatusPage />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
        <Footer />
        <DonateModal show={isModalOpen} />
      </adminContext.Provider>
    </div>
  );
}

export default App;
