import { useAtomValue } from "jotai";
import { Carousel } from "react-bootstrap";
import { languageAtom } from "../../Layout/Header/state";
import { avatars, greetingsText } from "./GreetingsText";

import CarouselItem from "./CarouselItem";
import { transformedImageUrl } from "../../../utils";

export default function Testimonials() {
  const language = useAtomValue(languageAtom);
  const { header, content = [] } = greetingsText(language);

  return (
    <div className="relative my-6 h-full overflow-hidden">
      <Carousel variant="dark" interval={null} controls={false}>
        {content.map((member, i) => {
          const cardImageUrl = transformedImageUrl({ url: avatars[i] });

          const { name, testimonial, title } = member;
          return (
            <Carousel.Item key={`member-${i}`}>
              <CarouselItem
                title={title}
                header={header}
                name={name}
                content={testimonial}
                avatar={cardImageUrl}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
