export function contactDetailsText(language) {
  switch (language) {
    case "en":
      return {
        headerText: "Let's stay connected",
        buttonText: "Send Message",
        emailTitle: "Email",
        emailContent: "info@hoh.am",
        phoneTitle: "Phone",
        phoneContent: "374 10 242968",
        locationTitle: "Address",
        locationContent: `Dro Street 22/5 Yerevan 0069 Armenia`,
        socialTitle: "Social",
        form: {
          name: "Name",
          email: "E-mail Address",
          text: "Enter your message",
          button: "Submit",
          errorMessage: "An error occurred. Please try again.",
          successMessage:
            "Thank you for your message. A member of our team will contact you shortly."
        }
      };
    case "de":
      return {
        headerText: "Bleiben wir in Verbindung",
        buttonText: "Nachricht senden",
        emailTitle: "Email",
        emailContent: "info@hoh.am",
        phoneTitle: "Telefon",
        phoneContent: "374 10 242968",
        locationTitle: "Adresse",
        locationContent: "Dro Straße 22/5 Erewan 0069 Armenien",
        socialTitle: "Sozial",
        form: {
          name: "Name",
          email: "E-Mail-Adresse",
          text: "Geben Sie Ihre Nachricht ein",
          button: "Absenden",
          errorMessage: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
          successMessage:
            "Danke für Ihre Nachricht. Ein Mitglied unseres Teams wird sich in Kürze mit Ihnen in Verbindung setzen."
        }
      };
    case "am":
      return {
        headerText: "Կապի մեջ",
        buttonText: "Ուղարկել նամակ",
        emailTitle: "Էլ հասցե",
        emailContent: "info@hoh.am",
        phoneTitle: "Հեռախոս",
        phoneContent: "374 10 242968",
        locationTitle: "Հասցե",
        locationContent: "Դրոյի 22/5 Երևան 0069 Հայաստան",
        socialTitle: "Սոց.",
        form: {
          name: "Անուն",
          email: "Էլ․ հասցե",
          text: "Մուտքագրել նամակը",
          button: "Ուղարկել",
          errorMessage: "Խնդրում ենք կրկին փորձել։",
          successMessage: "Շնորհակալություն Ձեր նամակի համար, մեր թիմակիցը շուտով կկապվի Ձեզ հետ"
        }
      };
    default:
  }
}
