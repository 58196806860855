import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./style.css";

import useAxios from "../../axios/useAxios";
import { adminContext, NewsContext } from "../Context";
import EditModal from "../Reusable/Card/Modals/EditModal";
import SinglePostWrapper from "../Reusable/SinglePostWrapper";
import SpinnerLoader from "../Reusable/Spinner";

import { useAtomValue } from "jotai";
import Error from "../Reusable/Error";
import Paginate from "../Reusable/Paginate";
import { offsetAtom } from "../Reusable/Paginate/state";
import ArticleCard from "../UI/Cards/ArticleCard";
import CardContainer from "../UI/Layout/CardContainer";
import DynamicHelmet from "../Helmet/DynamicHelmet";

function News() {
  const { id } = useParams();

  // Local States
  const [modalShow, setModalShow] = useState(false);

  // Global States
  const offset = useAtomValue(offsetAtom);

  const { isAdmin } = useContext(adminContext);

  const { response, loading, error, axiosFetch } = useAxios();
  const { articles, currentArticle, category } = response;

  const params = {
    limit: 8,
    sort: "name,-date",
    offset,
    currentArticleId: id
  };

  async function fetchNews() {
    await axiosFetch({
      url: "/articles",
      params
    });
  }

  useEffect(
    () => {
      fetchNews();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset, id]
  );

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <NewsContext.Provider value={fetchNews}>
      <DynamicHelmet title={"HOH | News"} />
      {isAdmin && (
        <div className="admin-tools absolute top-16 sm:top-20 z-10">
          <div
            className="bg-darkGreen text-sm px-2 py-1 cursor-pointer"
            onClick={() => setModalShow(true)}>
            Create
          </div>
          <EditModal type="articles" show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
      <SinglePostWrapper data={currentArticle} category={category} isLoading={loading} />
      {error && <Error />}
      <Container>
        <CardContainer>
          {articles
            ?.filter((post) => post._id !== id)
            .map((post) => (
              <ArticleCard post={post} key={post._id} category={category} />
            ))}
        </CardContainer>
      </Container>
      <Paginate articles={response} />
    </NewsContext.Provider>
  );
}

export default News;
