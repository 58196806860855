export const handleTitleNameLanguage = (value) => {
  if (value === "am") {
    return "Նոր Նախագիծ";
  } else if (value === "de") {
    return "Neues Projekt";
  } else if (value === "en") {
    return "New Project";
  }
};

export const handleFormContentLanguage = (value) => {
  if (value === "am") {
    return "Պարունակությունը";
  } else if (value === "de") {
    return "Inhalt";
  } else if (value === "en") {
    return "Content";
  }
};

export const handleFormNameLanguage = (value) => {
  if (value === "am") {
    return "Անվանումը";
  } else if (value === "de") {
    return "Name";
  } else if (value === "en") {
    return "Name";
  }
};

export const handleFormImageLanguage = (value) => {
  if (value === "am") {
    return "Ավելցնել Նկարը";
  } else if (value === "de") {
    return "Bild hinzufügen";
  } else if (value === "en") {
    return "Add Image";
  }
};

export const handleFormCloseLanguage = (value) => {
  if (value === "am") {
    return "Չեղարկել";
  } else if (value === "de") {
    return "Schließen";
  } else if (value === "en") {
    return "Close";
  }
};

export const handleFormConfirmLanguage = (value) => {
  if (value === "am") {
    return "Հաստատել";
  } else if (value === "de") {
    return "Bestätigen";
  } else if (value === "en") {
    return "Confirm";
  }
};

export const projectContentText = {
  en: {
    projectsHome: {
      mainTitle: "Our Projects",
      mainButton: "View All"
    },
    card: {
      cardButton: "Read more"
    },
    archivedText: "Completed Projects"
  },
  de: {
    projectsHome: {
      mainTitle: "Unsere Projekte",
      mainButton: "Alle anzeigen"
    },
    card: {
      cardButton: "Weiterlesen"
    },
    archivedText: "Abgeschlossene Projekte"
  },
  am: {
    projectsHome: {
      mainTitle: "Մեր Նախագծերը",
      mainButton: "Տեսնել ամբողջը"
    },
    card: {
      cardButton: "Կարդալ Ավելին"
    },
    archivedText: "Ավարտված Ծրագրերը"
  }
};

export function projectModalText(language) {
  switch (language) {
    case "en":
      return {
        modalTitle: "New Project",
        form: {
          name: {
            label: "Name",
            placeholder: "Enter projects name"
          },
          content: {
            label: "Content",
            placeholder: "Enter projects content"
          },
          image: {
            label: "Add Images"
          },
          submitButton: "Submit",
          closeButton: "Close"
        }
      };
    case "de":
      return {
        modalTitle: "Neues Projekt",
        form: {
          name: {
            label: "Name",
            placeholder: "Geben Sie den Projektnamen ein"
          },
          content: {
            label: "Content",
            placeholder: "Geben Sie den Projektinhalt ein"
          },
          image: {
            label: "Füge Bilder hinzu"
          },
          submitButton: "Einreichen",
          closeButton: "Absagen"
        }
      };
    case "am":
      return {
        modalTitle: "Նոր Նախագիծ",
        form: {
          name: {
            label: "Անվանումը",
            placeholder: "Մուտքագրել անվանումը"
          },
          content: {
            label: "Բովանդակություն",
            placeholder: "Մուտքագրել բովանդակությունը"
          },
          image: {
            label: "Ավելցնել Նկարներ"
          },
          submitButton: "Հաստատել",
          closeButton: "Չեղարկել"
        }
      };
    default:
  }
}
