import { useState } from "react";
import { shortParagraphText } from "./content";

function ShortParagraph({ data, language }) {
  const [isReadMoreSet, setIsReadMoreSet] = useState(false);
  const { readMoreButtonText, readLessButtonText } = shortParagraphText[language];

  return (
    <div className="w-full mx-auto">
      <div
        className={`${
          !isReadMoreSet && "line-clamp-3 md:line-clamp-2"
        } text-prmDark text-sm sm:text-base mt-3 leading-6 font-light`}
        dangerouslySetInnerHTML={{ __html: data }}></div>
      {data?.length > 200 && (
        <button
          className="mt-3 cursor-pointer uppercase bg-yellow-400 font-light border py-1 px-2 text-prmDark text-[10px] sm:text-[12px] float-right"
          onClick={() => setIsReadMoreSet(!isReadMoreSet)}>
          {isReadMoreSet ? readLessButtonText : readMoreButtonText}
        </button>
      )}
    </div>
  );
}

export default ShortParagraph;
