import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { languageAtom } from "../../Layout/Header/state";
import { FormLabel, InputWrapper } from "../../UI/InputElements";
import { donationTranslatedText } from "../content";
import { currencyAtom, defaultValuesAtom, errorAtom } from "../state";

const currencies = [
  {
    text: "amd",
    icon: "֏",
    code: "051"
  },
  {
    text: "usd",
    icon: "$",
    code: "840"
  },
  {
    text: "eur",
    icon: "€",
    code: "978"
  },
  {
    text: "rub",
    icon: "₱",
    code: "648"
  }
];

function CustomValues() {
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [selected, setSelected] = useAtom(defaultValuesAtom);

  const [errorMessage, setErrorMessage] = useAtom(errorAtom);

  const language = useAtomValue(languageAtom);
  const { form: { amountLabel, validationAmountLabel } = {} } = donationTranslatedText[language];

  const [value, setValue] = useState(0);
  const isError = errorMessage && !value && !selected;

  function validatedLabel() {
    if (isError) {
      return validationAmountLabel;
    }

    return `${amountLabel} *`;
  }

  function setDonationValue(e) {
    const inputValue = e.target.value;

    setErrorMessage(false);
    setSelected(Number(inputValue));

    if (inputValue > 250000) return;

    setValue(inputValue);
  }

  function handleCurrencyChange(e, curr) {
    e.preventDefault();
    setErrorMessage(false);
    setCurrency(curr);
    setSelected(0);
  }

  return (
    <InputWrapper>
      <FormLabel isError={isError} forInput={"amount"} label={validatedLabel()}>
        <div className="flex">
          <span className="self-center text-base text-zinc-600">{currency.icon}</span>
          <input
            id="amount"
            className="text-3xl w-full text-[#4079e7] ml-1 p-1 no-spinner "
            type="number"
            onChange={setDonationValue}
            value={selected || value}
            max={250000}
            required
          />

          <DropdownButton align="end" id="dropdown-currency" title={currency.text.toUpperCase()}>
            {currencies
              .filter((cur) => cur.text !== currency.text)
              .map((cur, i) => (
                <Dropdown.Item
                  as="button"
                  key={`currency-${i}`}
                  onClick={(e) => handleCurrencyChange(e, cur)}>
                  {cur.text.toUpperCase()}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </div>
      </FormLabel>
    </InputWrapper>
  );
}

export default CustomValues;
