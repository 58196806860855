import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/20/solid";
import { useAtom } from "jotai";
import { getUrlSearchParamOffset } from "../../../utils";
import { currentPageAtom, offsetAtom } from "./state";
import { getPagMargin } from "./utils";
import PaginationItem from "./PaginationItem";

export default function Index({ articles }) {
  const { links, total, pageTotal, articles: data } = articles || {};

  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const [offset, setOffset] = useAtom(offsetAtom);

  const getToPage = (e, button) => {
    e.preventDefault();

    if (!links) return null;

    switch (button) {
      case "next":
        if (currentPage !== pageTotal) {
          setCurrentPage(currentPage + 1);
        } else return;
        break;

      case "prev":
        if (currentPage >= 2) {
          setCurrentPage(currentPage - 1);
        } else return;

        break;

      case "first":
        if (currentPage !== 1) {
          setCurrentPage(1);
        } else return;

        break;

      case "last":
        if (currentPage !== pageTotal) {
          setCurrentPage(pageTotal);
        } else return;

        break;

      default:
        return;
    }

    const offsetParam = getUrlSearchParamOffset(links[button]);
    setOffset(offsetParam);
  };

  if (!data) return null;

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mx-auto max-w-7xl">
      <div className="flex flex-1 justify-between sm:hidden">
        {links?.prev && (
          <a
            onClick={(e) => getToPage(e, "prev")}
            href={"#"}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Previous
          </a>
        )}
        {links?.next && (
          <a
            onClick={(e) => getToPage(e, "next")}
            href={"#"}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Next
          </a>
        )}
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{Number(offset) + data?.length}</span> to{" "}
            <span className="font-medium">{}</span> of <span className="font-medium">{total}</span>{" "}
            results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination">
            <a
              href="#"
              onClick={(e) => getToPage(e, "first")}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="#"
              onClick={(e) => getToPage(e, "prev")}
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {getPagMargin(pageTotal, currentPage).map((num, index) => {
              return <PaginationItem page={num} key={`pagination - ${index}`} />;
            })}
            <a
              onClick={(e) => getToPage(e, "next")}
              href="#"
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            <a
              onClick={(e) => getToPage(e, "last")}
              href="#"
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
