import { useAtom } from "jotai";
import { Lightbox } from "react-modal-image";
import { lightBoxImgUrlAtom, lightBoxModalAtom } from "./state";

const LightBoxModal = () => {
  const [isOpen, setIsOpen] = useAtom(lightBoxModalAtom);
  const [imgUrl, setImgUrl] = useAtom(lightBoxImgUrlAtom);

  const onClose = () => {
    if (isOpen) {
      setIsOpen(false);
      setImgUrl(null);
    }
  };

  if (!isOpen) return null;

  return <Lightbox hideZoom hideDownload medium={imgUrl} large={imgUrl} onClose={onClose} />;
};

export default LightBoxModal;
