import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import { en } from "suneditor/src/lang";
import "suneditor/dist/css/suneditor.min.css";

const RichEditor = ({ content, onChange, playerName }) => {
  const options = {
    plugins: plugins,
    height: 250,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "link"
      ]
    ]
  };
  return (
    <SunEditor
      key={playerName}
      placeholder="Please type here..."
      name={playerName}
      setContents={content}
      lang="en"
      setDefaultStyle="font-family: Arial; font-size: 14px;"
      setOptions={options}
      onChange={onChange}
    />
  );
};

export default RichEditor;
