export const getPagMargin = (pageTotal, currentPage) => {
  const pagLength = 3;
  const baseLength = Math.floor(pagLength / 2);

  return [...Array(pageTotal ?? 0)]
    .map((_, i) => i + 1)
    .filter((currentIndex) => {
      //checks for the first three items
      if (currentPage <= pagLength && currentIndex <= pagLength) {
        return currentIndex;
      }

      //checks for the central items
      if (currentPage > pagLength && currentPage < pageTotal) {
        return currentIndex >= currentPage - baseLength && currentIndex <= currentPage + baseLength;
      }

      //checks for last three items
      if (currentPage === pageTotal) {
        return currentIndex > pageTotal - pagLength;
      }

      return false;
    });
};
