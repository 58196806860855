export const paymentStatusPageText = {
  en: {
    greetingMessage: "Dear",
    statusMessage: `thank you for your kind contribution`,
    homeButtonText: "Back to main page"
  },
  de: {
    greetingMessage: "Lieber/Liebe",
    statusMessage: `vielen Dank für Ihren freundlichen Beitrag`,
    homeButtonText: "Zurück zur Hauptseite"
  },
  am: {
    greetingMessage: "Հարգելի",
    statusMessage: `շնորհակալություն ձեր նվիրատվության համար`,
    homeButtonText: "Վերադառնալ գլխավոր էջ"
  }
};
