import { useAtomValue } from "jotai";
import ContainerWrapper from "../../../ui/wrappers/container-wrapper.jsx";
import { classNames, transformedImageUrl } from "../../../utils";
import { languageAtom } from "../../Layout/Header/state";
import { aboutUsContent } from "../data/index.js";
import { history } from "../data/story.js";
import { useState } from "react";

function TheStory() {
  const language = useAtomValue(languageAtom);
  const isArm = language === "am";
  const [isOpen, setIsOpen] = useState(false);

  const { mainTitleText, readMoreButtonText, readLessButtonText } = aboutUsContent[language];

  return (
    <div className="overflow-hidden">
      <ContainerWrapper>
        <div className="flex justify-center md:justify-start mt-2 mb:mt-5">
          <h1
            className={classNames(
              "text-[28px] font-bold md:text-5xl mb-10 uppercase text-prmOrange",
              isArm ? "font-kirk" : ""
            )}>
            {mainTitleText}
          </h1>
        </div>
        {history
          .sort((a, b) => a.year - b.year)
          .slice(0, isOpen ? history.length : 1)
          .map((story, i) => {
            const isEven = (i & 1) === 0;
            return (
              <div
                key={i}
                className="w-full  text-prmDark flex flex-column justify-center items-center">
                <div
                  className={classNames(
                    "relative flex flex-col-reverse  justify-center items-center md:gap-2",
                    isEven ? "md:flex-row-reverse" : "md:flex-row"
                  )}>
                  <span
                    className={classNames(
                      "absolute font-kirk text-[150px] md:text-[300px] -z-2 opacity-5 font-bold",
                      isEven ? "md:right-0" : "md:left-0"
                    )}>
                    {story.year}
                  </span>
                  <div className="flex flex-col md:pl-6 lg:pl-24 z-10 -translate-y-10 md:translate-y-0">
                    <div className="lg:max-w-[80%]">
                      <h3
                        className={classNames(
                          "text-prmOrange font-bold font-kirk text-6xl md:text-7xl",
                          isEven ? "" : "text-end"
                        )}>
                        {story.year}
                      </h3>
                      <p className="text-sm md:text-base font-nuni font-light lg:text-lg">
                        {story.text[language]}
                      </p>
                    </div>
                  </div>
                  <img
                    className={classNames(
                      "rounded-full md:p-5 aspect-square max-w-[50%] md:max-w-[40%]  object-cover w-full",
                      isEven ? "self-end" : "self-start"
                    )}
                    src={transformedImageUrl({
                      url: story.img.url,
                      width: 600,
                      ratio: 1 / 1
                    })}
                    alt="about-us-cover"
                  />
                </div>
              </div>
            );
          })}
        <div className="w-full flex items-end justify-end mt-10">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-sm px-2 py-1 rounded-full text-white bg-prmOrange self-end hover:bg-prmOrangeHover">
            {!isOpen ? readMoreButtonText : readLessButtonText}
          </button>
        </div>
      </ContainerWrapper>
    </div>
  );
}

export default TheStory;
