import "./style.css";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { languageAtom } from "../../Layout/Header/state";
import { homeContentText } from "../content";
import VolunteersModal from "./VolunteersModal";
import { ReactComponent as Rectangle } from "./vectors/frame-rectangular.svg";
import { ReactComponent as Circle } from "./vectors/frame-round.svg";
import { transformedImageUrl } from "../../../utils";

function Volunteer() {
  const [modalShow, setModalShow] = useState(false);
  const language = useAtomValue(languageAtom);
  const { volunteers } = homeContentText(language);
  const coverUrl =
    "https://res.cloudinary.com/di8iypeft/image/upload/v1698090155/HouseOfHope/statics/volunteers_e5upay.jpg";
  const imageUrl = transformedImageUrl({ url: coverUrl, ratio: "16:9", width: 800 });

  return (
    <Container fluid className="volunteers">
      <div className="volunteers-wrapper">
        <div>
          <div className="volunteers-main">
            <h1 className="mb-3">{volunteers.mainPage.mainTitle}</h1>
            <button onClick={() => setModalShow(true)}>{volunteers.mainPage.mainButton}</button>
          </div>
        </div>
        <div className="image-wrapper">
          <div className="gradient-wrapper">
            <Circle className="circle" />
            <img src={imageUrl} alt="volunteers" />
            <Rectangle className="rectangle" />
          </div>
        </div>
      </div>
      <VolunteersModal show={modalShow} onHide={() => setModalShow(false)} language={language} />
    </Container>
  );
}

export default Volunteer;
