export function homeContentText(language) {
  switch (language) {
    case "en":
      return {
        greetings: {
          header: "With love and dedication"
        },
        jumbotron: {
          mainTitle: "Welcome to House of Hope",
          aboutUsButton: "About Us",
          donateButton: "Donate Now",
          badge: "Years of Charity Kitchen"
        },
        volunteers: {
          mainPage: {
            mainTitle: "Become a Proud Volunteer",
            mainButton: "Become a Volunteer"
          },
          modalPage: {
            modalTitle: "Become a Proud Volunteer",
            volunteerName: "Your Name",
            volunteerEmail: "Your E-Mail",
            volunteerPhone: "Your Phone Number",
            volunteerMessage: "Your Message",
            volunteerConfirmButton: "Confirm",
            volunteerCloseButton: "Close"
          }
        },
        contactUs: {
          headerText: "Let's stay connected",
          buttonText: "Send Message",
          emailTitle: "Reach Us",
          emailContent: "contact@houseofhope.com",
          phoneTitle: "Phone",
          phoneContent: "+ 374-10-242968",
          locationTitle: "Location",
          locationContent: { street: "Dro Street, 22/5", city: "Yerevan, 0069, Armenia" },
          socialTitle: "Social"
        }
      };
    case "de":
      return {
        greetings: {
          header: "Mit Liebe und Hingabe"
        },
        jumbotron: {
          mainTitle: "Willkommen im Haus der Hoffnung",
          aboutUsButton: "Uber Uns",
          donateButton: "Jetzt Spenden",
          badge: "Jahre Küche der Barmherzigkeit"
        },
        volunteers: {
          mainPage: {
            mainTitle: "Freiwilliger werden",
            mainButton: "Freiwilliger werden"
          },
          modalPage: {
            modalTitle: "Freiwilliger werden",
            volunteerName: "Ihr Name",
            volunteerEmail: "Ihre E-Mail",
            volunteerPhone: "Ihre Telefonnummer",
            volunteerMessage: "Ihr Nachricht",
            volunteerConfirmButton: "Bestätigen",
            volunteerCloseButton: "Absagen"
          }
        },
        contactUs: {
          headerText: "Bleiben wir in Verbindung",
          buttonText: "Nachricht senden",
          emailTitle: "Erreiche uns",
          emailContent: "contact@houseofhope.com",
          phoneTitle: "Telefon",
          phoneContent: "+ 374-10-242968",
          locationTitle: "Ort",
          locationContent: { street: "Dro Street, 22/5", city: "Erewan, 0069, Armenien" },
          socialTitle: "Sozial"
        }
      };
    case "am":
      return {
        greetings: {
          header: "Սիրով եվ Նվիրումով"
        },
        jumbotron: {
          mainTitle: "Բարի Գալուստ Հուսո Տուն",
          aboutUsButton: "Մեր Մասին",
          donateButton: "Նվիրաբերել",
          badge: "Գթության Խոհանոց"
        },
        volunteers: {
          mainPage: {
            mainTitle: "Դառնալ Կամավոր",
            mainButton: "Կամավորագրվել"
          },
          modalPage: {
            modalTitle: "Դառնալ Կամավոր",
            volunteerName: "Ձեր Անունը",
            volunteerEmail: "Ձեր Էլ-Հասցեն",
            volunteerPhone: "Ձեր Հեռախոսահամարը",
            volunteerMessage: "Ձեր Նամակը",
            volunteerConfirmButton: "Հաստատել",
            volunteerCloseButton: "Չեղարկել"
          }
        },
        contactUs: {
          headerText: "Կապի մեջ",
          buttonText: "Ուղարկել նամակ",
          emailTitle: "Կապվել մեզ հետ",
          emailContent: "contact@houseofhope.com",
          phoneTitle: "Հեռախոս",
          phoneContent: "+ 374-10-242968",
          locationTitle: "Հասցե",
          locationContent: { street: "Dro Street, 22/5", city: "Երևան, 0069, Հայաստան" },
          socialTitle: "Սոց."
        }
      };
    default:
  }
}
