export const privacyTranslationText = {
  en: {
    body: {
      privacyText: "Privacy Policy",
      mainSubtitle: "Please read and get acquainted with the Terms and Conditions",
      termsAndConditionsOne:
        "I agree to transfer money to the 'House of Hope Charity Center' fund as a donation, which will be used for the implementation of charity projects.",
      termsAndConditionsTwo: `I agree that " House of Hope Charity Center " Foundation will process my personal data in any way that does not violate the law. in particular, I agree to the Foundation posting my name as a donor on its website, social networks and publications.`,
      refundSubtitle: `Terms of refund`,
      refundTerms: [
        "If the error occurs by the fault of the 'House of Hope Charity Center' foundation",
        "If the Donor has entered an incorrect amount",
        "If there are exceptional circumstances"
      ],
      refundCondition:
        "In case of making a wrong transfer, it is necessary to specify name, surname, identity document data, complete postal address and phone number. Please include details of how you made the donation, the date and amount of the donation, and your supporting circumstances. After receiving the letter, we will answer you within 2 working days.",
      refundRules: [
        "If the Donor submits a refund request after 90 days, the Foundation cannot refund any money.",
        "Refunds are made within 60 days."
      ]
    }
  },
  de: {
    body: {
      privacyText: "Datenschutz-Bestimmungen",
      mainSubtitle:
        "Bitte lesen und machen Sie sich mit den allgemeinen Bestimmungen und Bedingungen vertraut",
      termsAndConditionsOne: `Ich bin damit einverstanden, Geld als Spende an die Stiftung „Wohltätigkeitszentrum Haus der Hoffnung“ zu überweisen, das für die Durchführung von wohltätigen Projekten verwendet wird.`,
      termsAndConditionsTwo: `Ich stimme zu, dass die Stiftung „Wohltätigkeitszentrum Haus der Hoffnung“ meine persönlichen Daten auf die Weise verarbeitet, die nicht gegen das Gesetz verstößt. Insbesondere stimme ich zu, dass die Stiftung meinen Namen als Spender auf ihrer Website, in sozialen Medien und in Beiträgen veröffentlicht.`,
      refundSubtitle: `Rückgabebedingungen des Geldes`,
      refundTerms: [
        "Wenn der Fehler auf ein Verschulden der Stiftung „Wohltätigkeitszentrum Haus der Hoffnung“ zurückzuführen ist.",
        "Wenn der Spender einen falschen Betrag eingegeben hat.",
        "Wenn außergewöhnliche Umstände vorliegen"
      ],
      refundCondition:
        "Bei einer Fehlüberweisung ist die Angabe von Name, Nachname, Ausweisdaten, vollständiger Postanschrift und Telefonnummer erforderlich. Bitte geben Sie an, wie Sie die Spende getätigt haben, das Datum, die Höhe der Spende und Ihre Begründung. Nach Erhalt des Schreibens werden wir Ihnen innerhalb von 2 Werktagen antworten.",
      refundRules: [
        "Wenn der Spender nach 90 Tagen einen Antrag für Rückgabe stellt, kann die Stiftung kein Geld zurückerstatten.",
        "Rückgaben erfolgen innerhalb von 60 Tagen."
      ]
    }
  },
  am: {
    body: {
      privacyText: "Գաղտնիության քաղաքականություն",
      mainSubtitle: `Խնդրում ենք կարդալ և ծանոթանալ Դրույթներին ու Պայմաններին`,
      termsAndConditionsOne: `Ես համաձայն եմ գումար փոխանցել «Հուսո տուն բարեգործական կենտրոն» հիմնադրամին որպես նվիրաբերություն, որը կօգտագործվի բարեգործական ծրագրերի իրականացման համար:`,
      termsAndConditionsTwo:
        "Ես համաձայնվում եմ, որ «Հուսո տուն բարեգործական կենտրոն» հիմնադրամը մշակի իմ անձնական տվյալները` 'օրենքին չհակասող ցանկացած ձևով. մասնավորապես, ես համաձայն եմ, որ Հիմնադրամը տեղադրի իմ անունը` որպես նվիրատու իր կայք էջում, սոցիալական ցանցերում և հրապարակումներում։",
      refundSubtitle: `Գումարի ետ վերադարձման պայմանները`,
      refundTerms: [
        "Եթե սխալն առաջացել է «Հուսո տուն բարեգործական կենտրոն» հիմնադրամի մեղավորությամբ",
        "Եթե Դոնորը սխալ գումարի չափ է մուտքագրել ",
        "Եթե առկա են բացառիկ հանգամանքներ"
      ],
      refundConditions:
        "Սխալ փոխանցում կատարելու դեպքում անհրաժեշտ է նշել անուն, ազգանուն, անձը հաստատատող փաստաթղթի տվյալները, փոստային ամբողջական հասցեն և հեռախոսահամարը։ Խնդրում ենք ներառել մանրամասներ այն մասին, թե ինչպես եք կատարել նվիրատվությունը, նշեք նիրաբերության ամսաթիվը և գումարը, ինչպես նաև Ձեր հիմնավորող հանգամանքները: Նամակն տանալուց հետո մենք կպատասխանենք Ձեզ 2 աշխատանքային օրվա ընթացքում:",
      refundRules: [
        "Եթե Նվիրաբերողը գումարի հետ վերադարձի մասին դիմում ներկայացնում է 90 օր անց, ապա այս դեպքում Հիմնադրամը չի կարող ետ վերադարձնել որևէ գումար:",
        "Գումարի հետ վերադարձն իրականացվում է 60 օրվա ընթացքում:"
      ]
    }
  }
};
