import { useAtomValue } from "jotai";
import { languageAtom } from "../../Layout/Header/state";
import { infoDataText } from "./infoData";
import InfoGraphicItem from "./InfographicItem";

const Infographic = () => {
  const language = useAtomValue(languageAtom);
  const translations = infoDataText[language]["stats"];

  return (
    <div className="w-full relative h-full ">
      <div
        className={
          "-translate-y-12 md:-translate-y-18 flex justify-center w-full flex-wrap gap-2 lg:gap-3 item-center relative z-10"
        }>
        {translations.map((info, i) => (
          <InfoGraphicItem key={i} title={info.title} number={info.number} />
        ))}
      </div>
    </div>
  );
};

export default Infographic;
