import { useCallback, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import useAxios from "../../../axios/useAxios";
import {
  handleModalDeleteLanguage,
  handleModalDeleteNoLanguage,
  handleModalDeleteYesLanguage
} from "../../News/content";
import Error from "../../Reusable/Error";
import SmallLoader from "../../Reusable/SmallLoader";
import { teamMemberContext } from "../Team";

function MemberDeleteModal({ member, language, ...props }) {
  const getTeam = useContext(teamMemberContext);

  const { loading, error, axiosFetch } = useAxios();

  const handleDelete = useCallback(async () => {
    member.avatar &&
      (await axiosFetch({
        method: "post",
        url: `/teams/${member._id}/delete-avatar`
      }));

    const res = await axiosFetch({
      method: "delete",
      url: `/teams/${member._id}`
    });

    if (res.ok) {
      getTeam();
    }
  }, [member.avatar, member._id, axiosFetch, getTeam]);

  const handleClose = useCallback(() => {
    props.onHide();
  }, [props]);

  return (
    <Modal {...props} size="sm" centered>
      {error && <Error />}
      <Modal.Body className="d-flex justify-content-center">
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <h4 className="text-center">{handleModalDeleteLanguage(language)}</h4>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{handleModalDeleteNoLanguage(language)}</Button>
        <Button onClick={handleDelete}>{handleModalDeleteYesLanguage(language)}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MemberDeleteModal;
