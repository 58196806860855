export function groupCategories(categories) {
  return categories.reduce((category, current) => {
    if (!category[current.category]) {
      category[current.category] = [];
    }
    category[current.category].push(current);

    return category;
  }, {});
}
