import { useEffect } from "react";
import "./style.css";

import SpinnerLoader from "../../Reusable/Spinner";
import ArticleCard from "../../UI/Cards/ArticleCard";
import CardContainer from "../../UI/Layout/CardContainer";

import { useAtomValue } from "jotai";
import useAxios from "../../../axios/useAxios";
import { offsetAtom } from "../../Reusable/Paginate/state";
import ContainerTitle from "../../UI/Layout/ContainerTitle";

function NewsHome() {
  const offset = useAtomValue(offsetAtom);

  const { response: articles, loading, axiosFetch } = useAxios();

  const params = {
    limit: 4,
    sort: "name,-date",
    offset,
    noFilter: true
  };

  async function fetchArticles() {
    await axiosFetch({
      url: "/articles",
      params
    });
  }

  useEffect(
    () => {
      fetchArticles();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset]
  );

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <>
      <ContainerTitle title={"news"} navTo={"news"} />
      <CardContainer>
        {articles?.articles?.map((post) => (
          <ArticleCard post={post} key={post._id} category={articles.category} />
        ))}
      </CardContainer>
    </>
  );
}

export default NewsHome;
