import DynamicHelmet from "../Helmet/DynamicHelmet";
import HeroElement from "./Hero";
import Infographic from "./Infographic/Infographic";
import NewsHome from "./NewsHome";
import PrioDonations from "./PrioDonations";
import SwiperCards from "./PrioDonations/SwiperCards";
import ProjectHome from "./ProjectHome";
import Testimonials from "./Testimonials";
import VideoWrapper from "./VideoWrapper";
import Volunteer from "./Volunteers";

function Home() {
  return (
    <div className="max-w-screen-3xl mx-auto w-full">
      <DynamicHelmet title={"House of Hope - Yerevan"} />
      <HeroElement />
      <div className="max-w-screen-xl mx-auto px-3">
        <Infographic />
        <PrioDonations />
        <SwiperCards />
        <VideoWrapper />
        <NewsHome />
        <Testimonials />
        <ProjectHome />
        <Volunteer />
      </div>
    </div>
  );
}

export default Home;
