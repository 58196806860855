import { useAtomValue } from "jotai";
import ContainerTitle from "../../UI/Layout/ContainerTitle";
import { languageAtom } from "../../Layout/Header/state";
import { videoWrapperText } from "./content";
import LazyLoadYouTube from "./LazyLoadYouTube";

function VideoWrapper() {
  const language = useAtomValue(languageAtom);
  const translation = videoWrapperText[language];
  return (
    <div>
      <ContainerTitle title={"aboutUs"} navTo={"/about-us"} />
      <div className="w-full flex flex-col md:flex-row gap-2 justify-around items-center mt-3">
        <div className="w-full bg-yellow-400 flex-1 p-1">
          <LazyLoadYouTube videoId={"Sy_BsVCt2QA"} />
        </div>
        <div className="text-prmDark text-start self-start p-1 sm:p-4 flex-1 flex flex-col gap-2">
          <span className="text-xs tracking-widest">11.10.2023</span>
          <h2 className="sm:text-2xl">{translation.header}</h2>
          <p className="text-sm sm:text-base">{translation.paragraph}</p>
        </div>
      </div>
    </div>
  );
}

export default VideoWrapper;
