import { useAtom } from "jotai";
import { languageAtom } from "./state";
import { useState, useRef, useEffect, useCallback } from "react";

function LanguageDropdown() {
  const dropdownRef = useRef(null);

  //local states
  const [isOpen, setIsOpen] = useState(false);

  //global state
  const [language, setLanguage] = useAtom(languageAtom);

  const chooseLanguage = useCallback(
    (flag) => {
      setLanguage(flag);

      if (isOpen) {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  // handles the dropdown close function if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="text-black relative z-50 flex justify-start">
      <span
        onClick={() => setIsOpen(!isOpen)}
        className={`fi fi-${language === "en" ? "gb" : language} cursor-pointer`}></span>
      {isOpen && (
        <div className="flex flex-col gap-3 p-3 pt-2 top-8 bg-white absolute -left-4 rounded-b-md shadow-sm">
          {["am", "de", "en"].map((flag, index) => {
            if (language !== flag) {
              return (
                <span
                  onClick={() => chooseLanguage(flag)}
                  key={`${flag}-${index}`}
                  className={`fi fi-${flag === "en" ? "gb" : flag} cursor-pointer`}></span>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default LanguageDropdown;
