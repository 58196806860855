import { useAtom, useAtomValue } from "jotai";
import { prioDonationText } from "../../Home/PrioDonations/content";
import { languageAtom } from "../../Layout/Header/state";
import {
  FormCheckBox,
  FormInput,
  FormLabel,
  FormSelect,
  InputWrapper
} from "../../UI/InputElements";
import { donationTranslatedText } from "../content";
import { donorDetailsAtom, errorAtom } from "../state";

const DonorDetails = () => {
  const [details, setDetails] = useAtom(donorDetailsAtom);

  // Global State
  const language = useAtomValue(languageAtom);
  const errorMessage = useAtomValue(errorAtom);
  const { cause: currentCause } = useAtomValue(donorDetailsAtom);

  const { form: { nameLabel, emailLabel, causeLabel, validationNameLabel, anonymousLabel } = {} } =
    donationTranslatedText[language];

  const projects = prioDonationText[language];

  let formSelectProjects = [];

  Object.values(projects).forEach((project) => {
    // Ensures the currentCause is excluded from the projects list
    if (project.projectTitle !== currentCause) {
      formSelectProjects.push(project.projectTitle);
    }
  });

  const isError = details.name === "" && errorMessage;

  function validatedLabel() {
    if (isError) {
      return validationNameLabel;
    }

    return `${nameLabel} *`;
  }

  return (
    <>
      <InputWrapper>
        <FormLabel isError={isError} label={validatedLabel()} forInput={"name"}>
          <FormInput
            id={"name"}
            type={"text"}
            callback={(e) => setDetails({ ...details, name: e.target.value })}
            value={details.name}
            required
          />
        </FormLabel>
      </InputWrapper>
      <InputWrapper>
        <FormLabel label={emailLabel} forInput={"email"}>
          <FormInput
            id={"email"}
            type={"email"}
            callback={(e) => setDetails({ ...details, email: e.target.value })}
            value={details.email}
          />
        </FormLabel>
      </InputWrapper>
      <InputWrapper>
        <FormLabel label={causeLabel} forInput={"cause"}>
          <FormSelect
            currentCause={currentCause}
            selections={formSelectProjects}
            callback={(e) => setDetails({ ...details, cause: e.target.value })}
          />
        </FormLabel>
      </InputWrapper>
      <InputWrapper>
        <div className="flex h-10 w-full items-center gap-3">
          <FormCheckBox
            id={"checkbox"}
            type={"checkbox"}
            callback={(e) => setDetails({ ...details, isAnonymous: e.target.checked })}
            value={details.isAnonymous}
          />
          <span className="text-xs tracking-widest uppercase ">{anonymousLabel}</span>
        </div>
      </InputWrapper>
    </>
  );
};

export default DonorDetails;
