import { useContext, useCallback } from "react";
import { getPartnerContext } from "..";
import { Modal, Button } from "react-bootstrap";
import { partnerContent } from "../content";
import Error from "../../Reusable/Error";
import SmallLoader from "../../Reusable/SmallLoader";
import useApi from "../../../axios/useAxios";

function DeletePartnerModal({ partner, language, ...props }) {
  const getPartners = useContext(getPartnerContext);
  const { _id, avatar } = partner;
  const { error, loading, axiosFetch } = useApi();

  const { deleteModalText: { toDeleteText, notToDeleteText, askToDeleteText } = {} } =
    partnerContent[language];

  const handleDelete = useCallback(async () => {
    if (avatar) {
      await axiosFetch({
        method: "post",
        url: `/partners/${_id}/delete-avatar`
      });
    }
    await axiosFetch({
      url: `/partners/${_id}`,
      method: "delete"
    });
    if ((!loading, !error)) {
      props.onHide();
      getPartners();
    }
  }, [avatar, _id, axiosFetch, loading, error, props, getPartners]);

  const handleClose = useCallback(() => {
    props.onHide();
  }, [props]);

  return (
    <Modal {...props} size="sm" centered>
      {error && <Error />}
      <Modal.Body className="d-flex justify-content-center">
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <h4 className="text-center">{askToDeleteText}</h4>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{notToDeleteText}</Button>
        <Button onClick={handleDelete}>{toDeleteText}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeletePartnerModal;
