export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getUrlSearchParamOffset = (url) => {
  if (!url) return undefined;

  const params = new URLSearchParams(url);

  return params.get("offset");
};

export const transformedImageUrl = ({
  url = "https://res.cloudinary.com/di8iypeft/image/upload/v1729425075/HouseOfHope/statics/story1_qwrl0x.png",
  width = 400,
  height,
  ratio = false,
  focusPoints = []
}) => {
  const transformations = [];

  if (focusPoints.length) {
    const [x, y] = focusPoints;
    transformations.push("c_crop");
    if (x) transformations.push(`x_${x}`);
    if (y) transformations.push(`y_${y}`);
  } else {
    transformations.push("f_auto", "q_auto", "g_auto", "c_fill"); // Default to automatic face detection if customFocus is not enabled
  }

  if (width) transformations.push(`w_${width}`);
  if (height) transformations.push(`h_${height}`);
  if (ratio) transformations.push(`ar_${ratio}`);

  if (!url?.includes("cloudinary") || !url.includes("/upload/")) return url;

  const [base, path] = url.split("/upload/");

  const transformationsString = transformations.join(",");
  return `${base}/upload/${transformationsString}/${path}`;
};
