import { useAtomValue } from "jotai";
import { languageAtom } from "../../Layout/Header/state";
import { footerTranslationText } from "./content";
import { useNavigate } from "react-router-dom";
import ContactUs from "../ContactUs";

export default function Footer() {
  const lang = useAtomValue(languageAtom);
  const navigate = useNavigate();

  const { navigation, copyrightText } = footerTranslationText[lang];

  const social = [
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=100080679501210",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      )
    }
  ];

  return (
    <footer>
      <ContactUs />
      <div className="mx-auto max-w-7xl overflow-hidden pt-20 pb-8 px-6 sm:pb-12 lg:px-8">
        <nav
          className="-mb-6 columns-2 flex-col lg:flex-row flex  items-center justify-center lg:space-x-12"
          aria-label="Footer">
          {Object.keys(navigation).map((item) => {
            const navItem = navigation[item];

            return (
              <div key={item} className="pb-6">
                <span
                  onClick={() => navigate(navItem.href)}
                  className="text-sm cursor-pointer leading-6 text-gray-600 hover:text-gray-900">
                  {navItem.name}
                </span>
              </div>
            );
          })}
        </nav>

        <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <p className="text-center text-xs leading-5 text-gray-500">{copyrightText}</p>
          <div className="flex mt-2 justify-center space-x-10">
            {social.map((item) => (
              <span
                onClick={() => navigate(item.href)}
                key={item.name}
                className="text-gray-400 hover:text-gray-500 cursor-pointer">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
