import { classNames } from "../../../utils";

export default function FluidContainer({ dark = false, navId, children }) {
  return (
    <div
      id={navId}
      className={classNames(
        "mx-auto px-4 sm:px-6 lg:px-8 py-6 max-w-full",
        dark ? "bg-prmDark" : ""
      )}>
      <div className="mx-auto max-w-8xl">{children}</div>
    </div>
  );
}
