import { useAtom, useSetAtom } from "jotai";
import { classNames, transformedImageUrl } from "../../../utils";
import { donateModalAtom, donorDetailsAtom } from "../../Donate/state";

function PrioSwiperCard({ donation }) {
  const { imageUrl, projectTitle, projectDescription, donationText } = donation;

  // Global State
  const setModalIsOpen = useSetAtom(donateModalAtom);
  const [details, setCurrentCause] = useAtom(donorDetailsAtom);

  const onClickHandler = () => {
    setCurrentCause({ ...details, cause: projectTitle });
    setModalIsOpen(true);
  };

  return (
    <div
      className={classNames(
        "bg-yellow-400 max-w-xs w-full flex flex-col justify-center aspect-square rounded-sm shadow-md relative text-center",
        "before:bg-yellow-200 before:bg-opacity-60  before:z-10 before:absolute before:inset-1"
      )}>
      <img
        src={transformedImageUrl({ url: imageUrl })}
        alt="project-cover"
        className="object-cover w-full h-full grayscale absolute p-1"
      />
      <div className="p-2 text-prmDark z-10 flex flex-col gap-2">
        <h1 className="text-[14px] uppercase  font-semibold">{projectTitle}</h1>
        <p className="text-sm">{projectDescription}</p>
      </div>
      <button
        onClick={onClickHandler}
        className="bg-yellow-400 text-lg py-1 z-10 text-prmDark cursor-pointer px-2 rounded-md relative font-bold">
        {donationText}
      </button>
    </div>
  );
}

export default PrioSwiperCard;
