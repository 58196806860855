import { classNames } from "../../../utils";

export default function CarouselItem(props) {
  const { name, content, title, header, avatar } = props;

  return (
    <div className="mx-auto flex flex-col gap-6 py-16 px-2 h-full md:flex-row justify-center items-center max-w-7xl">
      <div className="flex w-1/2 justify-center items-center">
        <div
          style={{ clipPath: "circle(50% at 50% 50%)" }}
          className="h-full p-1 aspect-square bg-yellow-400 md:w-[70%] w-full">
          <div style={{ clipPath: "circle(50% at 50% 50%)" }} className="relative bg-white  h-full">
            <img
              className="relative s w-full h-full object-contain "
              src={avatar}
              alt={`house-of-hope-${title}`}
            />
          </div>
        </div>
      </div>
      <div className={classNames("w-full text-prmDark  font-arm")}>
        <header className="text-xl font-semibold">{header}</header>
        <figure className="relative isolate pt-6">
          <blockquote className="text-sm font-light">
            <p>{content}</p>
          </blockquote>
          <figcaption className="mt-8 text-base">
            <div className="font-semibold">{name}</div>
            <div className="mt-1">{title}</div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}
