export function dateExtractor(value, language) {
  const isGerman = language === "de" ? "de-DE" : "en-En";

  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.toLocaleString(isGerman, { month: "short" });
  const day = date.getDate();

  return `${day} ${month} ${year}`;
}

export function formatDate(dateStr) {
  const date = new Date(dateStr);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}
