import { useAtomValue } from "jotai";
import { createContext, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoCreateOutline } from "react-icons/io5";
import useApi from "../../axios/useAxios";
import { adminContext } from "../Context";
import { languageAtom } from "../Layout/Header/state";
import Error from "../Reusable/Error";
import Loader from "../Reusable/Loader";
import NewPartner from "./NewPartner/NewPartner";
import NewPartnerModal from "./NewPartner/NewPartnerModal";
import { partnerContent } from "./content.js";
import "./style.css";
import { transformedImageUrl } from "../../utils";
import DynamicHelmet from "../Helmet/DynamicHelmet";

export const getPartnerContext = createContext();

function Partners() {
  const { isAdmin } = useContext(adminContext);
  const language = useAtomValue(languageAtom);
  const { mainTitleText, mainParagraphFooterText, mainParagraphText } = partnerContent[language];

  const [isMore, setIsMore] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const { response: partners, error, loading, axiosFetch } = useApi();

  function getPartners() {
    axiosFetch({
      url: "/partners"
    });
  }

  useEffect(() => {
    getPartners();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="partners">
      <DynamicHelmet title={"HOH | Partners"} />
      <Container>
        <getPartnerContext.Provider value={getPartners}>
          <div className="partners-main-wrapper">
            <Col md={12} lg={6} className="partners-header">
              <h2>{mainTitleText}</h2>
              <p>{mainParagraphText}</p>
              <p>{mainParagraphFooterText}</p>
              <div className="mobile-button-header">
                <button onClick={() => setIsMore(!isMore)}>
                  {isMore ? "Show less..." : "Show More..."}
                </button>
              </div>
            </Col>
            <Col md={12} lg={5} className="image-wrapper">
              <img
                src={transformedImageUrl({
                  url: "https://res.cloudinary.com/di8iypeft/image/upload/v1698090152/HouseOfHope/statics/photo-three_tgzvrn.jpg",
                  width: 1000
                })}
                alt="partners-cover"
              />
            </Col>
          </div>
          {isAdmin && (
            <div className="admin-tools">
              <IoCreateOutline onClick={() => setModalShow(true)} />
              <NewPartnerModal show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          )}
          <Row className="partners-body">
            {loading && <Loader />}
            {error && <Error />}
            {partners?.map((partner) => (
              <NewPartner key={partner._id} partner={partner} admin={isAdmin} language={language} />
            ))}
          </Row>
        </getPartnerContext.Provider>
      </Container>
    </Container>
  );
}

export default Partners;
