export function deleteModalLanguage(value) {
  switch (value) {
    case "en":
      return {
        deleteModalQuestion: "Are you sure you want to delete this article?",
        deleteItem: "yes",
        cancelItem: "no"
      };
    case "de":
      return {
        deleteModalQuestion: "Sind Sie sich sicher, dass Sie das entfernen wollen?",
        deleteItem: "ja",
        cancelItem: "nein"
      };
    case "am":
      return {
        deleteModalQuestion: "Ցանկանում եք հեռացնե՞լ այս նյութը",
        deleteItem: "այո",
        cancelItem: "ոչ"
      };
    default:
  }
}

export function editModalLanguage(value) {
  switch (value) {
    case "en":
      return {
        editModalTitle: "Create New Item",
        editDateText: "Choose Date",
        namePlaceholder: "Name",
        imagePlaceholder: "Add image",
        contentPlaceholder: "Content",
        closeButtonText: "Close",
        confirmButtonText: "Confirm"
      };
    case "de":
      return {
        editModalTitle: "Neuen Artikel erstellen",
        editDateText: "Wählen Sie Datum",
        namePlaceholder: "Name",
        imagePlaceholder: "Bild hinzufügen",
        contentPlaceholder: "Inhalt",
        closeButtonText: "Schließen",
        confirmButtonText: "Bestätigen"
      };
    case "am":
      return {
        editModalTitle: "Տեղադրել նոր նյութ",
        editDateText: "Ընտրել տարեթիվը",
        namePlaceholder: "Անվանում",
        imagePlaceholder: "Ավելցնել նկարը",
        contentPlaceholder: "Պարունակություն",
        closeButtonText: "Փակել",
        confirmButtonText: "Հաստատել"
      };

    default:
  }
}
