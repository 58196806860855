import { useAtomValue } from "jotai";
import { classNames } from "../../../utils";
import { languageAtom } from "../Header/state";
import { contactDetailsText } from "./content";

const FormButton = ({ loading, error, isReady, reset }) => {
  const language = useAtomValue(languageAtom);
  const { form: { errorMessage, successMessage, button } = {} } = contactDetailsText(language);

  if (loading) {
    return <span className="text-xs">...loading</span>;
  }

  if (error || isReady) {
    return (
      <div
        onClick={() => reset()}
        className={classNames(
          "text-xs  px-2 py-1 rounded-sm text-center",
          error ? "bg-red-400 text-white" : "bg-green-400 text-prmDark"
        )}>
        {error ? errorMessage : successMessage}
      </div>
    );
  }

  return <button type="submit">{button}</button>;
};

export default FormButton;
