export const aboutUsContent = {
  am: {
    mainTitleText: "Մեր Պատմությունը",
    readMoreButtonText: "տեսնել ավելին",
    readLessButtonText: "փակել",
    team: {
      teamTitleText: "Մեր Աշխատակիցները",
      teamParagraphText: "Մեր Աշխատակիցները",
      newTeamMemberText: "Նոր Աշխատակից",
      trustees: "Հոգաբարձուների խորհուրդ"
    }
  },
  de: {
    mainTitleText: "Unsere Geschichte",
    readMoreButtonText: "mehr sehen",
    readLessButtonText: "weniger sehen",
    team: {
      teamTitleText: "Unser Mitarbeiter",
      teamParagraphText: "Unser Mitarbeiter",
      newTeamMemberText: "Neuer Mitarbeiter",
      trustees: "Stiftungsrat"
    }
  },
  en: {
    mainTitleText: "Our Story",
    readMoreButtonText: "see more",
    readLessButtonText: "see less",
    team: {
      teamTitleText: "Our Team",
      teamParagraphText: "Our Team",
      newTeamMemberText: "New Employee",
      trustees: "Board of Trustees"
    }
  }
};

export function mapCategory(category, language) {
  switch (category) {
    case "boardMember":
      switch (language) {
        case "en":
          return "Board of Trusties";
        case "am":
          return "Հոգաբարձուների խորհուրդ";
        case "de":
          return "Vorstand der Treuhänder";
        default:
          return "Board of Trusties";
      }
    case "supportTeam":
      switch (language) {
        case "en":
          return "Support Team";
        case "am":
          return "Օժանդակ աշխատակազմ";
        case "de":
          return "Unterstützungsteam";
        default:
          return "Support Team";
      }
    case "management":
      switch (language) {
        case "en":
          return "Administrative Team";
        case "am":
          return "Վարչական կազմ";
        case "de":
          return "Verwaltungsteam";
        default:
          return "Administrative Team";
      }
    case "projectTeam":
      switch (language) {
        case "en":
          return "Project Team";
        case "am":
          return "Ծրագրային աշխատակազմ";
        case "de":
          return "Projektteam";
        default:
          return "Project Team";
      }
    default:
      return "Unknown category";
  }
}
