import { useAtomValue } from "jotai";
import { Fragment, createContext, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoCreateOutline } from "react-icons/io5";
import useAxios from "../../../axios/useAxios";
import { adminContext } from "../../Context";
import { languageAtom } from "../../Layout/Header/state";
import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import NewTeamMemberModal from "../Modals/NewTeamMemberModal";
import { aboutUsContent, mapCategory } from "../data";
import TeamMember from "./TeamMember";
import { groupCategories } from "../utils";
import SwiperCarousel from "../../../ui/swipers/swiper-carousel";
import { SwiperSlide } from "swiper/react";
import { classNames } from "../../../utils";
import ContainerWrapper from "../../../ui/wrappers/container-wrapper";

export const teamMemberContext = createContext();

function Team() {
  const { isAdmin } = useContext(adminContext);
  const language = useAtomValue(languageAtom);
  const isArm = language === "am";

  const [modalShow, setModalShow] = useState(false);

  const { team: { teamTitleText } = {} } = aboutUsContent[language];
  const { response: team, error, loading, axiosFetch } = useAxios();
  const groupedTeams = groupCategories(team);

  async function getTeams() {
    axiosFetch({
      url: `teams`
    });
  }

  useEffect(
    () => {
      getTeams();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container fluid className="teams">
      <ContainerWrapper>
        <teamMemberContext.Provider value={getTeams}>
          <div className="admin-options">
            {isAdmin ? (
              <div className="bg-red-300">
                <IoCreateOutline onClick={() => setModalShow(true)} />
                <NewTeamMemberModal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            ) : null}
          </div>
          <div className="our-team-wrapper w-full">
            <div className={classNames("our-team-header uppercase mb-5", isArm ? "font-kirk" : "")}>
              <h2>{teamTitleText}</h2>
            </div>
            {Object.keys(groupedTeams)
              .sort((a, b) => {
                if (a === "management") return -1;
                if (b === "management") return 1;
                return 0;
              })
              .map((category, i) => {
                if (category === "boardMember") {
                  return null;
                }

                return (
                  <Fragment key={`${category}-${i}`}>
                    <div className="our-team-header mb-4 mt-5 uppercase">
                      <h4 className={isArm ? "font-kirk" : ""}>
                        {mapCategory(category, language)}
                      </h4>
                    </div>
                    <div className="team-container">
                      <SwiperCarousel>
                        {groupedTeams[category]
                          .sort((a, b) => b.sequence - a.sequence)
                          .map((member) => (
                            <SwiperSlide key={member._id}>
                              <TeamMember member={member} reload={getTeams} admin={isAdmin} />
                            </SwiperSlide>
                          ))}
                      </SwiperCarousel>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </teamMemberContext.Provider>
      </ContainerWrapper>
    </Container>
  );
}

export default Team;
