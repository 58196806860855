import { useCallback, useContext } from "react";
import { Button, Modal } from "react-bootstrap";

import { useAtomValue } from "jotai";
import useAxios from "../../../../../axios/useAxios";
import { NewsContext, ProjectContext } from "../../../../Context";
import { languageAtom } from "../../../../Layout/Header/state";
import Error from "../../../Error";
import SmallLoader from "../../../SmallLoader";
import { deleteModalLanguage } from "../../content";

function DeleteModal({ type, id, data, ...props }) {
  const language = useAtomValue(languageAtom);
  const { deleteModalQuestion, deleteItem, cancelItem } = deleteModalLanguage(language);

  const fetchNews = useContext(NewsContext);
  const fetchProjects = useContext(ProjectContext);

  const { loading, error, axiosFetch } = useAxios();

  const handleDelete = useCallback(async () => {
    if (data?.pictures?.length > 0) {
      await axiosFetch({
        method: "post",
        url: `/${type}/${id}/delete-pictures`
      });
    }
    await axiosFetch({
      method: "delete",
      url: `/${type}/${id}`
    });
    if (!loading && !error) {
      props.onHide();
      if (type === "articles") {
        await fetchNews();
      } else {
        await fetchProjects();
      }
    }
  }, [data, axiosFetch, type, id, loading, error, props, fetchNews, fetchProjects]);

  const handleClose = useCallback(() => {
    props.onHide();
  }, [props]);

  return (
    <Modal {...props} size="sm" centered>
      {error && <Error />}
      <Modal.Body className="d-flex justify-content-center">
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <div className="text-center">{deleteModalQuestion} </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{cancelItem}</Button>
        <Button onClick={handleDelete}>{deleteItem}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
