import "../../Donate/style.css";
import { useAtomValue } from "jotai";
import { classNames } from "../../../utils";
import { languageAtom } from "../../Layout/Header/state";
import { prioDonationText } from "./content";
import PrioCard from "./PrioCard";

function PrioDonations() {
  const language = useAtomValue(languageAtom);

  return (
    <div
      className={classNames(
        "hidden sm:flex lg:w-full justify-center flex-row flex-wrap text-center gap-y-4 gap-x-2 lg:gap-x-0 lg:gap-y-0 items-center z-10 relative ",
        "max-w-6xl mx-auto"
      )}>
      {Object.keys(prioDonationText.en).map((donation) => {
        const content = prioDonationText[language][donation];
        return content ? <PrioCard donation={content} key={content.projectTitle} /> : null;
      })}
    </div>
  );
}

export default PrioDonations;
