import { Fragment, useEffect } from "react";
import useAxios from "../../../axios/useAxios";
import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import ArticleCard from "../../UI/Cards/ArticleCard";
import CardContainer from "../../UI/Layout/CardContainer";
import ContainerTitle from "../../UI/Layout/ContainerTitle";

function ProjectHome() {
  const { response: projects, loading, error, axiosFetch } = useAxios();

  const params = {
    limit: 4,
    noFilter: true
  };

  async function getProjects() {
    await axiosFetch({
      url: "/projects",
      params
    });
  }

  useEffect(
    () => {
      getProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <ContainerTitle title={"projects"} navTo={"/projects"} />
      <CardContainer>
        {projects?.projects?.map((post) => (
          <Fragment key={post._id}>
            <ArticleCard post={post} category={projects.category} />
          </Fragment>
        ))}
      </CardContainer>
    </>
  );
}

export default ProjectHome;
